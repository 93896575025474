<template>
<div id="content">
  <div class="wp-content" v-html="post.content.rendered || ''">
      
  </div>
</div>
</template>

<script>
import {BASE_URL} from '../config.js';
export default {
    data() {
        return {
            post: []
        }
    },
    beforeMount() {
      this.post = {
        content : {
          rendered : ''
        }
      }
      
    },
    mounted() {
        var that = this;
         fetch(BASE_URL+'/wp-json/wp/v2/posts/17')
      .then((r) => r.json())
      .then(function(res) {
          console.log(res)
        that.post = res;
      } );
    
    },
}
</script>

<style lang="scss">
.wp-content {
  max-width: 60vw;
  margin: auto;
  margin-top: 100px;
  p {
    margin-top: 2em;
    text-shadow: 1px 1px 2px  #FFFFFFCC;
  }
}
</style>